import { createPortal } from 'react-dom';

import Backdrop from './Backdrop';

import classes from './Modal.module.css';

const Modal = ({ onClose, children, className }) => {
  const portalElement = document.getElementById('overlays');
  return (
    <>
      {onClose && createPortal(<Backdrop onClose={onClose} />, portalElement)}
      {createPortal(
        <div className={`${classes.modal} ${className}`}>{children}</div>,
        portalElement
      )}
    </>
  );
};

export default Modal;
