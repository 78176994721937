import { APP_PREFIX_PATH, APP_NAME } from '../configs/AppConfig';

export const NavbarLinks = [
  {
    to: APP_PREFIX_PATH,
    title: 'Home'
  },
  {
    to: APP_PREFIX_PATH + '/about-us',
    title: 'About Us'
  },
  {
    to: APP_PREFIX_PATH + '/products',
    title: 'Products'
  },
  {
    to: APP_PREFIX_PATH + '/responsibility',
    title: 'Responsibility'
  },
  {
    to: APP_PREFIX_PATH + '/careers',
    title: 'Careers'
  },
  {
    to: APP_PREFIX_PATH + '/contact-us',
    title: 'Contact Us'
  },
  // {
  //   to: APP_PREFIX_PATH + '/FAQ',
  //   title: 'FAQ'
  // },
];

export const NavbarSmallLinks = [
  {
    to: APP_PREFIX_PATH + '/profile',
    title: 'Profile',
    key: 'NavbarSmallLinks-profile'
  },
  {
    to: APP_PREFIX_PATH + '/dashboard',
    title: 'Dashboard'
  },
];

export const NavbarSideLinksPrivate = [
  {
    to: APP_PREFIX_PATH + '/auctions',
    title: 'Auctions'
  },
  {
    to: APP_PREFIX_PATH + '/physical-bidding',
    title: 'Physical Bidding'
  },
  {
    to: APP_PREFIX_PATH + '/profile',
    title: 'Profile',
    key: 'NavbarSideLinks-profile'
  },
  {
    to: APP_PREFIX_PATH + '/dashboard',
    title: 'Dashboard'
  },
  // {
  //   to: APP_PREFIX_PATH + '/profile/my-listing',
  //   title: 'My Listing',
  //   key: 'NavbarSideLinks-my-listing'
  // },
  // {
  //   to: APP_PREFIX_PATH + '/profile/settings',
  //   title: 'Settings',
  //   key: 'NavbarSideLinks-Settings'
  // },
  {
    to: APP_PREFIX_PATH + '/FAQ',
    title: 'FAQ'
  },
  {
    to: APP_PREFIX_PATH + '/contact-us',
    title: 'Contact us'
  },
  {
    to: APP_PREFIX_PATH + '/about-us',
    title: 'About us '
  }
];

export const profileNavLinks = [
  {
    to: '',
    title: 'Profile'
  },
  {
    to: 'my-listing',
    title: 'My Listings'
  },
  {
    to: 'settings',
    title: 'Settings'
  }
];

export const dashboardNavLinks = [
  {
    to: APP_PREFIX_PATH + '/dashboard',
    title: 'Dashboard'
  },
  // {
  //   to: APP_PREFIX_PATH + '/dashboard/auctions',
  //   title: 'Auctions'
  // },
  // {
  //   to: APP_PREFIX_PATH + '/dashboard/auctions/:auctionId',
  //   title: 'Auctions'
  // },
  {
    to: APP_PREFIX_PATH + '/dashboard/bids',
    title: 'Your Bids'
  },
  {
    to: APP_PREFIX_PATH + '/dashboard/watchlist',
    title: 'ShortList'
  },
  {
    to: APP_PREFIX_PATH + '/dashboard/wishlist',
    title: 'WishList'
  },
  {
    to: APP_PREFIX_PATH + '/dashboard/wins',
    title: 'My Wins'
  },
  {
    to: APP_PREFIX_PATH + '/dashboard/approve-bids',
    title: 'New Quote'
  },
  // {
  //   to: APP_PREFIX_PATH + '/dashboard/approve-bids',
  //   title: 'Approve Bids'
  // },
  // {
  //   to: APP_PREFIX_PATH + '/dashboard/payment',
  //   title: 'Make Payment'
  // }
  // {
  //   to: APP_PREFIX_PATH + '/dashboard/registration-list',
  //   title: 'My Registrations'
  // }
];

export const USER_LOCAL_STORAGE_KEY = `${APP_NAME}_user`;
export const USER_EXPIRATION_TIME = `${APP_NAME}_user_expiration_time`;

export const timeToExpire = 3600 * 1000;

export const expirationTime = new Date(
  new Date().getTime() + timeToExpire
).toISOString();

export const vehicleDeatailsStartingStep = 0;
export const maxVehicleDeatailsStep = 4;

export const overLookStartingStep = 4;
export const maxOverLookStep = 1;

export const auctionPriceStartingStep = 5;
export const maxAuctionPriceStep = 2;

export const AuctionType1 = 'Bank';
export const AuctionType2 = 'Insurance';
export const AuctionType3 = 'Consumer Auction';

export const AuctionTime1 = 'LIVE';
export const AuctionTime2 = 'UPCOMING';
export const AuctionTime3 = 'EXPIRED';

export const OpenAuction = 'Open';
export const CloseAuction = 'Close';

export const ASC = 'ASC';
export const DESC = 'DESC';

export const endTimeASC = 'End Time: Sooner To Later';
export const endTimeDESC = 'End Time: Later To Sooner';
export const inventoryCountASC = 'Inventory Count: Low To high';
export const inventoryCountDESC = 'Inventory Count: High To Low';
export const inventoryFirstENDING = 'First Ending';
export const inventoryLastENDING = 'Last Ending';

export const userBuyerType = 'Buyer';

export const PanType = 'Pan';
export const GstType = 'Gst';
export const AadhaarType = 'Aadhar';

export const DocPending = "PENDING";
export const DocVerified = "VERIFIED";
export const DocRejected = "REJECTED";

export const facebookLink = "https://www.facebook.com/vivifipharma";
export const linkedInLink = "https://www.linkedin.com/company/2360143?trk=tyah";
export const twitterLink = "https://twitter.com/vivifi1";
export const googlePlusLink = "https://plus.google.com/118193164712035126539/posts";
