import React, { useEffect, useState } from "react";
import classes from "./ContactUsDetail.module.css";
import { FormProvider } from "react-hook-form";
import { Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import Button from "components/UI/button/Button";
import { motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import { API_BASE_URL } from "configs/AppConfig";
import useWindowWidth from "helpers/useWindowWidth";

const rules = {
  name: [
    {
      required: true,
      message: "Required",
    },
  ],
  email: [
    {
      required: true,
      message: "Required",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  message: [
    {
      required: true,
      message: "Required",
    },
  ],
};

const ContactUsDetail = () => {
  //   const isBigScreen = window.innerWidth > 868;
  const [form] = Form.useForm();
  //   const [isLoading, setIsLoading] = useState(false);

  const width = useWindowWidth();
  const isBigScreen = width > 868;

  const { isLoading, mutateAsync: submitForm } = useMutation(
    async (formData) => {
      const response = await fetch(`${API_BASE_URL}enquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //   Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        // setNotifyMessage(data.message, "error");
        throw new Error(data.message);
      }
      //   setNotifyMessage("Added Document successfully", "success");
      if (data.success) {
        message.success("Enquiry submitted successfully.");
      }
      // return data.data;
    }
  );

  const { isFileUploading, mutateAsync: uploadFile } = useMutation(
    async (file, category = "enquiry") => {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("fileFor", category);
      const response = await fetch(`${API_BASE_URL}filemanager/files/upload`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (!response.ok) {
        // setNotifyMessage(data.message, "error");
        throw new Error(data.message);
      }
      //   setNotifyMessage("Added Document successfully", "success");
      if (data.data) {
        // const data = await fetchUserData(user.sessionToken);
        // if (data) {
        //   dispatch(userActions.updateUser({ user: data.data }));
        // }
      }
      return data.data;
    }
  );

  const onFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("values on submit", values);
        var fileInput = document.getElementById("attachmentInput");
        console.log("fileinput files", fileInput.files);
        if (fileInput?.files?.length > 0) {
          const response = await uploadFile(fileInput.files[0]);
          console.log("response after file uploading", response);
          values.files = response;
        }
        console.log("values final", values);
        submitForm(values);
      })
      .catch((info) => {
        console.log("info", info);
        //   message.error("Please enter all required field ");
      });
  };

  return (
    <div className={classes.contactUsContainer}>
      <div className={classes.contactUsWrapper}>
        <motion.div
          className={classes.contactUsForm}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.15 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -70 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <h1>Contact Form</h1>
          <Form
            layout="vertical"
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <Form.Item
              name="name"
              label="Name"
              rules={rules.name}
              className={classes.input}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={rules.email}
              className={classes.input}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="files"
              label="Attach files"
              //   className={classes.input}
            >
              <input
                type="file"
                name="attachment"
                id="attachmentInput"
                className={classes.fileInput}
              />
            </Form.Item>
            <Form.Item
              name="message"
              label="Message"
              rules={rules.message}
              className={classes.input}
            >
              <TextArea rows={4} placeholder="Enter Your Message" />
            </Form.Item>
          </Form>
          <Button
            className={classes.submit}
            onClick={onFinish}
            // loading={isLoading}
            disabled={isLoading}
          >
            Submit
          </Button>
        </motion.div>
        <div className={classes.contactUsDetails}>
          <motion.div
            className={classes.contactUsAddress}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: +70 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <h1>Contact Info</h1>
            <div className={classes.contactInfo}>
              <img src="/img/icons/call-grey.svg" alt="call" />
              <h2>Toll Free Number : 18008896116</h2>
            </div>
            <div className={classes.contactInfo}>
              <img src="/img/icons/email-grey.svg" alt="call" />
              <h2>Email : info@vivifipharma.com</h2>
            </div>
            <div className={classes.contactInfo}>
              <img src="/img/icons/location-grey.svg" alt="call" />
              <h2>
                No : 104, Ambe Residency, HSR Layout Sector(2),
                Somasundarapalya, Bangalore - 560 102, Karnataka, India.
              </h2>
            </div>
          </motion.div>
          <motion.div
            className={classes.contactUsMap}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: -50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15556.584372394263!2d77.65020600000001!3d12.898326!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14a11a335985%3A0x670a91483bc6968!2sVivifi%20Life%20sciences%20Pvt%20Ltd!5e0!3m2!1sen!2sus!4v1704694046416!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={
                isBigScreen
                  ? { borderRadius: "16px", border: "none" }
                  : { borderRadius: "8px", border: "none" }
              }
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Contact Us"
            ></iframe>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsDetail;
