import { useQuery } from "@tanstack/react-query";

import { PublicInformation } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";

import NavList from "./NavList";

import classes from "./Footer.module.css";
import AddressList from "./AddressList";
import CompanyDetails from "./CompanyDetails";

const CompanyList = [
  { name: "About Us", to: "/about-us" },
  { name: "Products", to: "/products" },
  { name: "Carrers", to: "/careers" },
  { name: "Responsibility", to: "/responsibility" },
];
const SupportNavList = [
  { name: "Privacy Policy", to: "/privacy-policy" },
  { name: "Terms & Conditions", to: "/terms-conditions" },
  { name: "Contact Us", to: "/contact-us" },
];

const Footer = () => {
  // const {
  //   isLoading,
  //   isError,
  //   data: informations,
  //   error,
  // } = useQuery([PublicInformation], async () => {
  //   const response = await fetch(`${API_BASE_URL}information/get/all`);

  //   const data = await response.json();
  //   if (!response.ok) {
  //     throw new Error(data.message);
  //   }

  //   return data;
  // });
  return (
    <>
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          {/* <NavList
            title="Overview"
            isInformation={true}
            links={informations ? informations.data : []}
          /> */}
          <CompanyDetails />
          <NavList title="Company" links={CompanyList} />
          <NavList title="Support" links={SupportNavList} />
          <AddressList />
        </div>
      </div>
      <div className={classes.footerBottom}>
        <p>
          Copyright © {new Date().getFullYear()} Vivifi Life Sciences Pvt Ltd -
          v1.1.1. All rights reserved | Developed By &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://riolabz.com/", "_blank")}
          >
            Riolabz
          </span>
        </p>
      </div>
    </>
  );
};

export default Footer;
