import { configureStore } from '@reduxjs/toolkit';

import modalReducer from './modal-slice';
import userReducer from './user-slice';
import auctionReducer from './auction-slice'

const store = configureStore({
  reducer: { modal: modalReducer, user: userReducer, auction: auctionReducer }
});

export default store;
