import React, { useState } from "react";
import classes from "./ProductListing.module.css";
import { useQuery } from "@tanstack/react-query";
import { ProductsDataKey } from "helpers/queriesDataKeys";
import { API_BASE_URL, APP_IMAGE_BASE_URL } from "configs/AppConfig";
import { Table, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "helpers/useWindowWidth";

const ProductListing = () => {
  const navigate = useNavigate();
  // const isBigScreen = window.innerWidth > 868;
  const width = useWindowWidth();
  const isBigScreen = width > 868;
  const [productsData, setProductsData] = useState([]);

  const {
    isLoading,
    isError,
    data: products,
    error,
  } = useQuery([ProductsDataKey], async () => {
    const response = await fetch(`${API_BASE_URL}product/public`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    console.log("response", response);
    setProductsData(response.data);
    return response.json();
  });
  console.log("products", products);
  console.log("products1", productsData);

  const tableColumns = [
    {
      title: "Photo",
      dataIndex: "image",
      render: (image) => (
        <div className={classes.imageWrapper}>
          <img
            src={`${APP_IMAGE_BASE_URL}${image}`}
            alt="img"
            className={classes.image}
          />
        </div>
      ),
    },
    {
      title: "Photo Name",
      dataIndex: "name",
      render: (name, row) => {
        return (
          <div
            className={classes.name}
            onClick={() => navigate(`/products/${row._id}`)}
          >
            {name}
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "60%",
      render: (description) => (
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className={classes.truncateLines}
        />
      ),
    },
    // {
    //   title: "Strength",
    //   dataIndex: "firstName",
    //   render: (status, row) => {
    //     return <div></div>;
    //   },
    // },
  ];

  const tableColumnsMobile = [
    {
      title: "Photo",
      dataIndex: "image",
      render: (image) => (
        <div className={classes.imageWrapper}>
          <img
            src={`${APP_IMAGE_BASE_URL}${image}`}
            alt="img"
            className={classes.image}
          />
        </div>
      ),
    },
    {
      title: "Photo Name",
      dataIndex: "name",
      render: (name, row) => {
        return (
          <div
            className={classes.name}
            onClick={() => navigate(`/products/${row._id}`)}
          >
            {name}
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.listContainer}>
      <div className={classes.listWrapper}>
        {products?.data?.length > 0 && (
          <Table
            columns={isBigScreen ? tableColumns : tableColumnsMobile}
            dataSource={products?.data}
            className={classes.table}
            pagination={false}
            // pagination={{
            //   position: "top",
            //   showSizeChanger: true,
            // }}
            // onChange={handleTableChange}
            loading={isLoading}
            rowKey="_id"
            // position="top"
          />
        )}
        {isLoading && (
          <Spin
            size="default"
            // fullscreen
            style={{ display: "flex", justifyContent: "center" }}
          />
        )}
        {products?.data?.length === 0 && (
          <h4 style={{ textAlign: "center", margin: "50px 0 160px 0" }}>
            No Products Found
          </h4>
        )}
      </div>
    </div>
  );
};

export default ProductListing;
