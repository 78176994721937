import Navbar from "./navbar/Navbar";

import classes from "./Layout.module.css";
// import Footer from './footer/Footer';
import { useRef } from "react";
import Footer from "./footer/Footer";

// Layout component => a higher level component which takes any other children components an place it in the main section
// Purpose => specifying the layout of the all the pages an includes the navbar
const Layout = ({ children, notScrollable }) => {
  const rootRef = useRef(document.getElementById("root"));

  // add the noScroll class on the root div when displaying a modal
  if (notScrollable) {
    rootRef.current.classList.add(classes.noScroll);
  }
  // remove the noScroll class on the root div when un-displaying a modal
  if (!notScrollable && rootRef.current.classList.contains(classes.noScroll)) {
    rootRef.current.classList.remove(classes.noScroll);
  }

  return (
    <>
      <nav className={`${classes.navbar}`}>
        <Navbar />
      </nav>
      <main className={`${classes.mainContent}`}>{children}</main>
      <footer className={classes.footer}>
        <Footer />
        {/* {window.innerWidth > 1020 && <Footer />} */}
      </footer>
    </>
  );
};

export default Layout;
