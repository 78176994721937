import { useDispatch } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import SignInForm from "components/forms/sign-in/SignInForm";
// import Button from 'components/UI/button/Button';
// import FacebookIcon from 'components/UI/icons/FacebookIcon';
// import GoogleIcon from 'components/UI/icons/GoogleIcon';
// import TwitterIcon from 'components/UI/icons/TwitterIcon';
import Modal from "components/UI/modal/Modal";
import ModalHeader from "components/UI/modal/ModalHeader";
import classes from "./SignIn.module.css";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const SignIn = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signupClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signup",
      })
    );
  };

  return (
    <Modal onClose={onClose} className={classes.modal}>
      <div className={classes.wrapper}>
        <ModalHeader onClose={onClose} title="Sign In" />
        <section className={classes.body}>
          <SignInForm />
          {/* <p className={classes.lineBreak}>Or</p> */}
          {/* <ul className={classes.socialBtns}>
            <li>
              <Button type="button" option="uncolored">
                <GoogleIcon />
                Sign In with Google
              </Button>
            </li>
            <li>
              <Button type="button" option="uncolored">
                <FacebookIcon />
                Sign In with Facebook
              </Button>
            </li>
            <li>
              <Button type="button" option="uncolored">
                <TwitterIcon />
                Sign In with Twitter
              </Button>
            </li>
          </ul> */}
        </section>
        <section className={classes.signinFooter}>
          <p>
            You don't have an account?
            <span
              className={classes.signUpLink}
              onClick={() => {
                onClose();
                navigate(`${APP_PREFIX_PATH}`, {
                  replace: true,
                });
              }}
              // onClick={signupClickHandler}
            >
              Sign Up
            </span>
          </p>
        </section>
      </div>
    </Modal>
  );
};

export default SignIn;
