import PageBanner from "components/UI/page-banner/PageBanner";
import ContactUsDetail from "components/page-content/contact-us-content/ContactUsDetail";
import React from "react";

const ContactUs = () => {
  return (
    <>
      <PageBanner
        page="Contact Us"
        title="Connecting with Vivifi Life Sciences for Inquiries and Collaborations"
        image="/img/page/contactus/banner1.png"
        mobileImage="/img/page/contactus/banner-mob1.png"
      />
      <ContactUsDetail />
    </>
  );
};

export default ContactUs;
