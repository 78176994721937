import { Link } from "react-router-dom";
import classes from "./CompanyDetails.module.css";
import {
  facebookLink,
  googlePlusLink,
  linkedInLink,
  twitterLink,
} from "helpers/constants";

const CompanyDetails = () => {
  return (
    <div className={classes.navListContainer}>
      {/* <h4> Address </h4> */}
      <img
        src="/img/vivifi-white.svg"
        alt="logo"
        className={classes.footerLogo}
      />
      <ul className={classes.navList}>
        <li>
          Vivifi Life Science is one of India's most respected pharmaceutical
          companies and is committed to delivering better health through
          superior products.
        </li>
        <li>
          <div className={`${classes.mediaLinks}`}>
            <a href={facebookLink} target="_blank" rel="noopener noreferrer">
              <img src="/img/icons/facebook.svg" alt="fb" />
            </a>
            <a href={linkedInLink} target="_blank" rel="noopener noreferrer">
              <img src="/img/icons/linkedin.svg" alt="ln" />
            </a>
            <a href={twitterLink} target="_blank" rel="noopener noreferrer">
              <img src="/img/icons/twitter.svg" alt="x" />
            </a>
            <a href={googlePlusLink} target="_blank" rel="noopener noreferrer">
              <img src="/img/icons/google-plus.svg" alt="g" />
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CompanyDetails;
