import { lazy, Suspense, useState } from "react";

import classes from "./FooterBanner.module.css";
import LoadingSpinner from "../../UI/loading-spinner/LoadingSpinner";
import { Carousel } from "antd";
import { motion } from "framer-motion";
import useWindowWidth from "helpers/useWindowWidth";

const FooterBanner = () => {
  // const isBigScreen = window.innerWidth > 868;
  const width = useWindowWidth();
  const isTabScreen = width > 868;
  const isBigScreen = width > 1200;

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.banner}>
        <img
          src={
            isBigScreen
              ? "/img/footerBanner/banner1.png"
              : isTabScreen
              ? "/img/footerBanner/tab-banner1.png"
              : "/img/footerBanner/mobile-banner1.png"
          }
          alt="banner"
          className={classes.bannerImage}
        />
        <motion.div
          className={classes.desc}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <h1>Mission</h1>
          <h2>
            Improving the Quality of Life by reducing the burden of disease."To
            be a caring pharmaceutical company helping to enhance health through
            quality products
          </h2>
        </motion.div>
      </div>
      <div className={classes.banner}>
        <img
          src={
            isBigScreen
              ? "/img/footerBanner/banner2.png"
              : isTabScreen
              ? "/img/footerBanner/tab-banner2.png"
              : "/img/footerBanner/mobile-banner2.png"
          }
          alt="banner"
          className={classes.bannerImage}
        />
        <motion.div
          className={classes.desc}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <h1>Vision</h1>
          <h2>
            To become the most admired pharmaceutical company in India having
            substantial global presence with leadership in market share and
            profits by Building distinctive sales and marketing capabilities.
          </h2>
        </motion.div>
      </div>
      <div className={classes.banner}>
        <img
          src={
            isBigScreen
              ? "/img/footerBanner/banner3.png"
              : isTabScreen
              ? "/img/footerBanner/tab-banner3.png"
              : "/img/footerBanner/mobile-banner3.png"
          }
          alt="banner"
          className={classes.bannerImage}
        />
        <motion.div
          className={classes.desc}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <h1>Responsibility</h1>
          <h2>
            Vivifi Life Science regulatory department has matured over time to
            meet the challenges of the present and the future.
          </h2>
        </motion.div>
      </div>
    </div>
  );
};

export default FooterBanner;
