import React from "react";
import classes from "./ResponsibilityBanner.module.css";
import { motion } from "framer-motion";
import useWindowWidth from "helpers/useWindowWidth";

const ResponsibilityBanner = () => {
  // const isBigScreen = window.innerWidth > 868;
  const width = useWindowWidth();
  const isTabScreen = width > 868;
  const isBigScreen = width > 1200;

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.banner}>
        <img
          src={
            isBigScreen
              ? "/img/page/responsibility/banner1.png"
              : isTabScreen
              ? "/img/page/responsibility/tab-banner1.png"
              : "/img/page/responsibility/banner-mob1.png"
          }
          alt="banner"
          className={classes.bannerImage}
        />
        <motion.div
          className={classes.desc}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <img src="/img/page/responsibility/logo1.svg" alt="logo" />
          <h1>Reliability</h1>
          <h2>
            Vivifi Life Sciences regulatory department has matured over time to
            meet the challenges of the present and the future.Vivifi Life
            Sciences is amongst the first few to install, qualify and implement
            CTD software for making filings as per regulatory guidelines.
            Dedicated teams have been put into place for handling API and
            Formulation-related activities.
          </h2>
        </motion.div>
      </div>
      <div className={classes.banner}>
        <img
          src={
            isBigScreen
              ? "/img/page/responsibility/banner2.png"
              : isTabScreen
              ? "/img/page/responsibility/tab-banner2.png"
              : "/img/page/responsibility/banner-mob2.png"
          }
          alt="banner"
          className={classes.bannerImage}
        />
        <motion.div
          className={classes.desc}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <img src="/img/page/responsibility/logo2.svg" alt="logo" />
          <h1>Commitments</h1>
          <h2>
            While business has grown multi-fold since its inception over 3 years
            ago, Vivifi Life Sciences has never lost sight of its core values,
            OCTAPACE. These values have formed the foundation on which the
            Vivifi has been built.OCTAPACE serves as a guide to all company
            employees and following the values is integral to realizing Vivifi
            Life Sciences' vision.
          </h2>
        </motion.div>
      </div>
      <div className={classes.banner}>
        <img
          src={
            isBigScreen
              ? "/img/page/responsibility/banner3.png"
              : isTabScreen
              ? "/img/page/responsibility/tab-banner3.png"
              : "/img/page/responsibility/banner-mob3.png"
          }
          alt="banner"
          className={classes.bannerImage}
        />
        <motion.div
          className={classes.desc}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <img src="/img/page/responsibility/logo3.svg" alt="logo" />
          <h1>Enivronment , Health & Safety</h1>
          <h2>
            Vivifi Life Sciences is a responsible and caring pharmaceutical
            company that is keenly aware of its duty to environment, health and
            safety. The company promotes environmental protection and insists on
            complying with all applicable environmental regulations. Every
            measure is taken for the prevention of pollution in all company.
          </h2>
        </motion.div>
      </div>
    </div>
  );
};

export default ResponsibilityBanner;
