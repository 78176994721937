import React from "react";
import classes from "./AboutUsContent.module.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import useWindowWidth from "helpers/useWindowWidth";

const AboutUsContent = () => {
  const navigate = useNavigate();

  // const isBigScreen = window.innerWidth > 868;
  const width = useWindowWidth();
  const isBigScreen = width > 868;

  return (
    <div className={classes.aboutContainer}>
      <motion.div
        className={classes.whoweare}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -70 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className={classes.image}>
          {isBigScreen && (
            <img src="/img/page/aboutus/whoweare.png" alt="who" />
          )}
        </div>
        <div className={classes.details}>
          <h4>Who We Are</h4>
          <h1>
            Empowering Health, Enriching Lives: Unveiling the Vivifi Journey
          </h1>
          <h2>
            It all started with a dream. A dream to be a world-class
            pharmaceutical company of Indian origin, which can match
            international standards on all counts. We worked towards our goal
            with full enthusiasm and commitment. As a result today, Vivifi Life
            Sciences has already made its presence felt in the pharmaceutical
            world. Vivifi Life Science is one of India's most respected
            pharmaceutical companies and is committed to delivering better
            health through superior products. By combining strategic research
            and expert industry knowledge, Vivifi Life Sciences aims to
            transform itself into a global pharmaceutical drug company.
          </h2>
        </div>
      </motion.div>
      <motion.div
        className={classes.thumbnails}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -30 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div className={classes.division}>
          <div className={classes.card}>
            <img src="/img/page/aboutus/quality.png" alt="quality" />
            <h4>Quality</h4>
          </div>
          <div className={classes.card}>
            <img src="/img/page/aboutus/responsibility.png" alt="resp" />
            <h4>Responsibility</h4>
          </div>
        </div>
        <div className={classes.division}>
          <div className={classes.card}>
            <img src="/img/page/aboutus/commitment.png" alt="commit" />
            <h4>Commitment</h4>
          </div>
          <div className={classes.card}>
            <img src="/img/page/aboutus/customer.png" alt="customer" />
            <h4>Customer Satisfaction</h4>
          </div>
        </div>
      </motion.div>
      <motion.div
        className={classes.wallPic}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: +30 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        {isBigScreen && <img src="/img/page/aboutus/wallpic.png" alt="wall" />}
        {!isBigScreen && (
          <img src="/img/page/aboutus/wallpicMob.png" alt="wallpic" />
        )}
      </motion.div>
      <motion.div
        className={classes.insights}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: +70 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className={classes.details}>
          <h4>Discover Insights</h4>
          <h1>Health Care Trivia: Unraveling the Wonders of Well-being</h1>
          <div className={classes.points}>
            <div className={classes.pointLine}>
              <img
                src="/img/page/aboutus/point.svg"
                alt="bullet"
                className={classes.bullet}
              />
              <h2>
                You burn more calories sleeping than you do watching the
                television.
              </h2>
            </div>
            <div className={classes.pointLine}>
              <img
                src="/img/page/aboutus/point.svg"
                alt="bullet"
                className={classes.bullet}
              />
              <h2>
                Banging your head against a wall will use roughly 150 calories
                an hour.
              </h2>
            </div>
            <div className={classes.pointLine}>
              <img
                src="/img/page/aboutus/point.svg"
                alt="bullet"
                className={classes.bullet}
              />
              <h2>
                Toothpaste was 'invented' over 4000 years ago, albeit not in the
                form we know it - Egyptians used crushed pumice stone and
                vinegar as a form of paste.
              </h2>
            </div>
            <div className={classes.pointLine}>
              <img
                src="/img/page/aboutus/point.svg"
                alt="bullet"
                className={classes.bullet}
              />
              <h2>
                Romans used urine as tooth cleaner (contains ammonia) and
                particularly saught out Portugeese product.
              </h2>
            </div>
            <div className={classes.pointLine}>
              <img
                src="/img/page/aboutus/point.svg"
                alt="bullet"
                className={classes.bullet}
              />
              <h2>
                Our nails grow at a rate of around 0.1 mm per day. So it would
                take roughly 3 months to replace an entire fingernail.
              </h2>
            </div>
          </div>
        </div>
        <div className={classes.image}>
          {isBigScreen && <img src="/img/page/aboutus/doc.png" alt="doc" />}
          {!isBigScreen && <img src="/img/page/aboutus/docMob.png" alt="doc" />}
        </div>
      </motion.div>
    </div>
  );
};

export default AboutUsContent;
