import PageBanner from "components/UI/page-banner/PageBanner";
import CareersDetail from "components/page-content/careers-content/CareersDetail";
import React from "react";

const Careers = () => {
  return (
    <>
      <PageBanner
        page="Careers"
        title="Careers at Vivifi: Where Passion Meets Innovation in Healthcare"
        image="/img/page/careers/careers.png"
        mobileImage="/img/page/careers/careers-mob.png"
      />
      <CareersDetail />
    </>
  );
};

export default Careers;
