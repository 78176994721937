import PageBanner from "components/UI/page-banner/PageBanner";
import AboutUsContent from "components/page-content/about-us-content/AboutUsContent";
import FooterBanner from "components/page-content/home-content/FooterBanner";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  // const { user, isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Used to show login modal if not already logged in
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate(`${APP_PREFIX_PATH}/dashboard`);
  //   } else {
  //     dispatch(
  //       modalActions.changeModalComponent({
  //         modalType: "signin",
  //       })
  //     );
  //   }
  // }, []);

  return (
    <>
      <PageBanner
        page="About Us"
        title="Crafting Tomorrow's Health: Our Story, Our Commitment."
        image="/img/page/aboutus.png"
        mobileImage="/img/page/aboutus-mob.png"
      />
      <AboutUsContent />
      <FooterBanner />
    </>
  );
};

export default AboutUs;
