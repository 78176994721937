import PageBanner from "components/UI/page-banner/PageBanner";
import ResponsibilityBanner from "components/page-content/responsibility-content/ResponsibilityBanner";
import ResponsibilityDetail from "components/page-content/responsibility-content/ResponsibilityDetail";
import React from "react";

const Responsibility = () => {
  return (
    <>
      <PageBanner
        page="Responsibility"
        title="Our Pledge to Responsible Pharmaceutical Manufacturing"
        image="/img/page/responsibility/responsibility.png"
        mobileImage="/img/page/responsibility/responsibility-mob.png"
      />
      <ResponsibilityDetail />
      <ResponsibilityBanner />
    </>
  );
};

export default Responsibility;
