import PageBanner from "components/UI/page-banner/PageBanner";
import ProductListing from "components/page-content/products-content/ProductListing";
import React from "react";

const Products = () => {
  return (
    <>
      <PageBanner
        page="Products"
        title="Explore Our Comprehensive Range of Quality Pharmaceuticals"
        image="/img/page/products.png"
        mobileImage="/img/page/products-mob.png"
      />
      <ProductListing />
    </>
  );
};

export default Products;
