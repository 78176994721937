
export const ConstantsDataKey = 'constants';
export const SettingsDataKey = 'settings';
export const BannersDataKey = 'banners';


export const StatesDateKey = 'states';
export const CitiesDateKey = 'cities';

export const PublicInformation = 'publicInformation';

export const ProductsDataKey = 'products';
