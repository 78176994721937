import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auctionData: {},
};

const auctionSlice = createSlice({
    name: 'auction',
    initialState,
    reducers: {
        updateAuctionData(state, action) {
            state.auctionData = action.payload.auctionData;
        }
    }
});

export const auctionActions = auctionSlice.actions;
export default auctionSlice.reducer;
