import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { API_BASE_URL, APP_IMAGE_BASE_URL } from "configs/AppConfig";
import { ProductsDataKey } from "helpers/queriesDataKeys";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classes from "./ProductDetail.module.css";
import PageBanner from "components/UI/page-banner/PageBanner";
import { motion } from "framer-motion";

const ProductDetail = () => {
  const { productUrl } = useParams();
  console.log("product url", productUrl);

  const [productDetail, setProductDetail] = useState();
  console.log("productDetail", productDetail);

  const {
    isLoading,
    isError,
    data: product,
    error,
  } = useQuery([ProductsDataKey], async () => {
    const response = await fetch(`${API_BASE_URL}product/${productUrl}/public`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
  console.log("product detail", product);

  useEffect(() => {
    // if (products?.rows) {
    //   const filtered = products.rows?.filter(
    //     (item) => item.product_uri === productUrl
    //   );
    //   console.log("filtered", filtered);
    //   if (filtered) setProductDetail(filtered[0]);
    // }
    if (product?.data) {
      setProductDetail(product.data);
    }
  }, [product]);

  return (
    <div className={classes.detailContainer}>
      <PageBanner
        page="Products"
        title="Explore Our Comprehensive Range of Quality Pharmaceuticals"
        image="/img/page/products.png"
        mobileImage="/img/page/products-mob.png"
      />
      {isLoading && <LoadingSpinner />}
      {!isLoading && !productDetail && (
        <div className={classes.noProduct}>No Product Found</div>
      )}
      {!isLoading && productDetail && (
        <motion.div
          className={classes.detailWrapper}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -70 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className={classes.image}>
            <img
              src={`${APP_IMAGE_BASE_URL}${productDetail.image}`}
              alt="img"
            />
          </div>
          <div className={classes.desc}>
            <h1>{productDetail.name}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: productDetail.description,
              }}
              className={classes.detail}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default ProductDetail;
