import classes from './HumburgerButton.module.css';

const HumburgerButton = ({ open, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${classes.container} ${open && classes.change} ${className}`}
    >
      <div className={classes.bar1}></div>
      <div className={classes.bar2}></div>
      <div className={classes.bar3}></div>
    </div>
  );
};

export default HumburgerButton;
