import { Link } from "react-router-dom";
import classes from "./AddressList.module.css";

const AddressList = () => {
  const addressList = [
    {
      icon: "/img/icons/call.svg",
      data: "Toll Free Number : 18008896116",
    },
    {
      icon: "/img/icons/email.svg",
      data: "Email : info@vivifipharma.com",
    },
    {
      icon: "/img/icons/location.svg",
      data: "No : 104, Ambe Residency, HSR Layout Sector(2), Somasundarapalya, Bangalore - 560 102, Karnataka, India.",
    },
  ];
  return (
    <div className={classes.navListContainer}>
      <h4> Address </h4>
      <ul className={classes.navList}>
        {addressList.map((item) => (
          <li key={item.icon}>
            <div className={classes.navListItem}>
              <img src={item.icon} alt="icon" />
              <h5>{item.data}</h5>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddressList;
