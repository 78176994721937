import { useCallback, useEffect, lazy, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { userActions } from 'store-redux/user-slice';

import { APP_PREFIX_PATH } from 'configs/AppConfig';

import Layout from 'components/layout/Layout';
import DispatchSignInModal from 'hooks/DispatchSignInModal';
import LoadingSpinner from 'components/UI/loading-spinner/LoadingSpinner';
import AboutUs from 'pages/AboutUs';
import Products from 'pages/Products';
import ProductDetail from 'components/page-content/products-content/ProductDetail';
import Responsibility from 'pages/Responsibility';
import Careers from 'pages/Careers';
import ContactUs from 'pages/ContactUs';

//Define the main pages urls here
const Home = lazy(() => import(`pages/Home`));
const FAQ = lazy(() => import(`pages/FAQ`));
// const AboutUs = lazy(() => import(`pages/AboutUs`));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 2000
    }
  }
});

const App = () => {
  // access the modalSlice State in the Store Reducer
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [checkForAuthorization, setCheckForAuthorization] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();

  //--------Use this code if there is login in the project----------------
  // const autoLoginHandler = useCallback(() => {
  //   dispatch(userActions.autoLogin());
  // }, [dispatch]);

  // useEffect(() => {
  //   // auto login first time app is running
  //   autoLoginHandler();
  //   setCheckForAuthorization(true);
  // }, [autoLoginHandler]);

  // const grantLoggedInAccess = checkForAuthorization && isLoggedIn;
  // const preventAccess = checkForAuthorization && !isLoggedIn;
  //----------------------------------------------------------------------

  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <Layout
        isDashboard={
          location.pathname.includes('dashboard') ||
          location.pathname.includes('profile')
        }
        notScrollable={modalIsVisible}
      >
        {/* Define Routes and corresponding components of main pages */}
        <Routes>
          <Route
            path={`${APP_PREFIX_PATH}`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/about-us`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/products`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Products />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/products/:productUrl`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ProductDetail />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/responsibility`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Responsibility />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/careers`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Careers />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/contact-us`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/FAQ`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <FAQ />
              </Suspense>
            }
          />

          {/* Redirect Route */}
          {/* {checkForAuthorization && (
            <Route
              path="*"
              element={<Navigate to={`${APP_PREFIX_PATH}`} replace />}
            />
          )} */}
        </Routes>
      </Layout>
    </QueryClientProvider>
  );
};

export default App;
