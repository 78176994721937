import React from "react";
import classes from "./PageBanner.module.css";
import { motion } from "framer-motion";
import useWindowWidth from "helpers/useWindowWidth";

const PageBanner = ({ image, mobileImage, title, page }) => {
  // const isBigScreen = window.innerWidth > 868;
  const width = useWindowWidth();
  const isBigScreen = width > 868;
  return (
    <motion.div
      className={classes.bannerContainer}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, y: -30 },
        visible: { opacity: 1, y: 0 },
      }}
    >
      <div className={classes.banner}>
        {isBigScreen && <img src={image} alt="banner" />}
        {!isBigScreen && <img src={mobileImage} alt="mob" />}
        <div className={classes.textWrapper}>
          <h1>{title}</h1>
          <h2>Home / {page}</h2>
        </div>
      </div>
    </motion.div>
  );
};

export default PageBanner;
