import React, { forwardRef } from 'react';

import classes from './CheckboxInput.module.css';

const CheckboxInput = forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      isCircle,
      onClick,
      required,
      type,
      onChange,
      checked,
      defaultChecked,
      register,
      validation
    },
    ref
  ) => {
    const reg = register ? { ...register(name, { ...validation }) } : null;
    return (
      <div className={`${classes.wrapper} ${isCircle ? classes.circle : ''}`}>
        <input
          onClick={onClick}
          id={id}
          name={name}
          type={type ? type : 'checkbox'}
          required={required}
          ref={ref}
          onChange={onChange}
          checked={checked}
          value={value}
          defaultChecked={defaultChecked}
          {...reg}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    );
  }
);

export default CheckboxInput;
