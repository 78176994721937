import React from "react";
import classes from "./CareersDetail.module.css";
import { motion } from "framer-motion";
import useWindowWidth from "helpers/useWindowWidth";

const CareersDetail = () => {
  // const isBigScreen = window.innerWidth > 868;
  const width = useWindowWidth();
  const isBigScreen = width > 868;

  return (
    <div className={classes.detailContainer}>
      <motion.div
        className={classes.detailWrapper}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -70 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className={classes.description}>
          <h1>
            At Vivifi Life Sciences, we believe in putting patients first in all
            we do. And we recognize that our employees are our single greatest
            asset in achieving this mission.
          </h1>
          <h1>
            So read on, because if what you're looking for in a career and what
            we offer as an employer match, we should talk. What's both crucial
            and refreshing about our culture is this: here, you'll find
            diversified experiences, strong ethics and integrity and a
            resounding passion for improving human health. As part of our team,
            you'll collaborate with talented and dedicated colleagues while
            developing and expanding your own career.
          </h1>
          <h1>
            Each one of us at Vivifi Life Science is joined by an extraordinary
            sense of purpose - bringing Vivifi Life Science's finest
            achievements to people around the world by delivering novel
            medicines and vaccines that address unmet medical needs.
          </h1>
          <h1>
            Qualified professionals having ingrained attributes of excellence
            and an urge to ascend,are welcome to contact at&nbsp;
            <a href={`mailto:info@vivifipharma.com`}>info@vivifipharma.com</a>,
            &nbsp;
            <a href={`mailto:admin@vivifipharma.com`}>admin@vivifipharma.com</a>
            .
          </h1>
        </div>
        <div className={classes.image}>
          {isBigScreen && <img src="/img/page/careers/image1.png" alt="img" />}
          {!isBigScreen && (
            <img src="/img/page/careers/image-mob1.png" alt="img" />
          )}
        </div>
      </motion.div>
      <motion.div
        className={classes.pointsWrapper}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: +70 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <img
          src={
            isBigScreen
              ? "/img/page/careers/point1.png"
              : "/img/page/careers/point-mob1.png"
          }
          alt="pt1"
        />
        <img
          src={
            isBigScreen
              ? "/img/page/careers/point2.png"
              : "/img/page/careers/point-mob2.png"
          }
          alt="pt2"
        />
        <img
          src={
            isBigScreen
              ? "/img/page/careers/point3.png"
              : "/img/page/careers/point-mob3.png"
          }
          alt="pt3"
        />
        <img
          src={
            isBigScreen
              ? "/img/page/careers/point4.png"
              : "/img/page/careers/point-mob4.png"
          }
          alt="pt4"
        />
        <img
          src={
            isBigScreen
              ? "/img/page/careers/point5.png"
              : "/img/page/careers/point-mob5.png"
          }
          alt="pt5"
        />
      </motion.div>
    </div>
  );
};

export default CareersDetail;
