import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";

import { NavbarLinks, NavbarSideLinksPrivate } from "helpers/constants";

import NavbarItem from "./navbar-item/NavbarItem";

import classes from "./NavbarList.module.css";
import Button from "components/UI/button/Button";
import { API_BASE_URL } from "configs/AppConfig";

const NavbarList = ({ navigationList = null, className, onClose, isSide }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const onClickSignoutHandler = () => {
    dispatch(userActions.logout());
    dispatch(modalActions.toggleModal());
    navigate("/");
  };

  let navList = null;
  if (navigationList) {
    navList = navigationList.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  } else if (!isSide || !isLoggedIn) {
    navList = NavbarLinks.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  } else {
    navList = NavbarSideLinksPrivate.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  }

  return (
    <ul className={`${classes.navbarList} ${className}`}>
      {navList}
      <>
        {/* --------Use this if employee login functionality is done------------ */}
        {/* {!isLoggedIn && isSide && (
          <NavbarItem onClick={setSigninModalHandler}>Log in</NavbarItem>
        )}
        {isLoggedIn && isSide && (
          <NavbarItem pageLink="/" onClick={onClickSignoutHandler}>
            Log out
          </NavbarItem>
        )} */}

        <div className={classes.navbarButtons}>
          <Button option="uncolored" className={classes.navButton}>
            Employee Login
          </Button>
          <Button
            option="uncolored"
            className={classes.navButton}
            onClick={() => window.open(`${API_BASE_URL}webmail`, "_blank")}
          >
            Employee Mail
          </Button>
        </div>
      </>
    </ul>
  );
};

export default NavbarList;
