import React from "react";
import classes from "./ResponsibilityDetail.module.css";
import { motion } from "framer-motion";
import useWindowWidth from "helpers/useWindowWidth";

const ResponsibilityDetail = () => {
  // const isBigScreen = window.innerWidth > 868;
  const width = useWindowWidth();
  const isBigScreen = width > 868;

  return (
    <div className={classes.detailContainer}>
      <motion.div
        className={classes.detailWrapper}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -70 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className={classes.description}>
          <h1>
            Quality is a fundamental value that we instill in all our employees,
            which reinforces our commitment to our customers. Our products pass
            through stringent tests backed by quality systems that ensure
            compliance with international standards. Production facilities are
            hygienic and in controlled environment. All the equipments are
            periodically inspected for consistency of quality from batch to
            batch. The plant confirms to GMP norms and GMP is being followed at
            each stage of manufacturing. The quality control laboratories are
            equipped with latest infrastructure for ensuring world class quality
            of medicines. Quality is of paramount importance, and even more so,
            in the health sector where the company deals directly with precious
            human life. Vivifi's total commitment to quality is well-explained
            in the 'Quality Policy' laid down by the Vivifi Life Sciences. The
            Quality Policy lays emphasis on continuous improvement, meeting
            customer expectations and all time regulatory compliance. Vivifi
            Life Sciences is a responsible corporate citizen and has taken
            several steps to integrate its quality and business objectives.
          </h1>
          <h1>
            &nbsp;&nbsp;&nbsp;The company has a full-fledged Corporate Quality
            and state-of-the-art Analytical Research department, which is a part
            of R&D center in Bangalore. Quality system standards are
            continuously upgraded by the Corporate Quality center by issuing
            Quality System Guidelines from time to time which become the basis
            for formulating standard operating procedures. The Corporate Quality
            center carries out internal audits of all manufacturing plants to
            check compliance with Good Manufacturing Practices (GMP).
          </h1>
        </div>
        <div className={classes.image}>
          {isBigScreen && (
            <img src="/img/page/responsibility/image.png" alt="img" />
          )}
          {!isBigScreen && (
            <img src="/img/page/responsibility/image-mob.png" alt="img" />
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default ResponsibilityDetail;
