import React from 'react';

const BackIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3477 7.09766V8.90234H4.17578L9.28906 14.0586L8 15.3477L0.652344 8L8 0.652344L9.28906 1.94141L4.17578 7.09766H15.3477Z"
        fill="#0D0A19"
      />
    </svg>
  );
};

export default BackIcon;
