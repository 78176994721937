

const PasswordEyeIcon = ({ className }) => {
    return (
    <svg 
        width="22" 
        height="17" 
        viewBox="0 0 22 17" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M1.5 12c0-2.25 3.75-7.5 10.5-7.5S22.5 9.75 22.5 12s-3.75 7.5-10.5 7.5S1.5 14.25 1.5 12zM12 16.75a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5zM14.7 12a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0z" 
        fill="#000000"
        // fill="#9A9EA7"
        />
    </svg>
    );
  };
  
  export default PasswordEyeIcon;
  