//API_MODE: "Dev" or "Live"
const dev = {
  API_ENDPOINT_URL: 'https://vivifiapitest.riolabz.com/api/v1/',
  API_MODE: "Dev",
};

const prod = {
  API_ENDPOINT_URL: 'https://vivifiapitest.riolabz.com/api/v1/',
  API_MODE: "Dev",
};

const test = {
  API_ENDPOINT_URL: 'https://vivifiapitest.riolabz.com/api/v1/',
  API_MODE: "Dev",
};

// const dev = {
//   API_ENDPOINT_URL: 'https://www.vivifipharma.com/',
//   API_MODE: "Live",
// };

// const prod = {
//   API_ENDPOINT_URL: 'https://www.vivifipharma.com/',
//   API_MODE: "Live",
// };

// const test = {
//   API_ENDPOINT_URL: 'https://www.vivifipharma.com/',
//   API_MODE: "Live",
// };

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
